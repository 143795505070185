const ActionButton = (props) => {
    //title
    //onClick
    //name
    //icon
    //btnColor
    //disabled
    return <button style={props.style ?? {borderRadius:'5px'}} disabled={props.disabled??false} title={props.title} type={props.type ?? "submit"} onClick={props.onClick} className={`btn-square btn-${props.btnColor??'primary'}`} >
        {props.icon && <i className={`fa fa-${props.icon} fa-fw `}></i>}
        {props.name && props.name}
    </button>
}

export default ActionButton;