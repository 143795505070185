export const DigitalSignHelper = {};

DigitalSignHelper.saveScreenEntity = (dataItem) => {
    let finalData = {
        _id: dataItem?._id,
        SID: dataItem?.SID,
        Name: dataItem?.Name,
        screenGroup: dataItem?.screenGroup && Object.keys(dataItem?.screenGroup).length > 0 ? {
            _id: dataItem?.screenGroup?._id,
            SID: dataItem?.screenGroup?.SID,
            Name: dataItem?.screenGroup?.Name,
            Description: dataItem?.screenGroup?.Description
        } : {},
        SerialNumber: dataItem?.SerialNumber,
        active: dataItem?.active,
        socketID: dataItem?.socketID,
        OsVer: dataItem?.OsVer,
        ModelNo: dataItem?.ModelNo,
        AppVer: dataItem?.AppVer,
        Company_id: dataItem?.Company_id,
        brightness: dataItem?.brightness,
        volume: dataItem?.volume,
        orientation: dataItem?.orientation,
        modBy: dataItem?.modBy,
        modOn: dataItem?.modOn,
        reboot: dataItem?.reboot ?? null
    }
    return finalData;
}