/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
    COMPANYTYPE,
    CONTENT_TYPE,
    ENTITYNAME,
    LOCALSTORAGE_KEY,
    LOGEVENT,
    MODULE,
    ORIENTATION,
} from "../../../framework/constant/constant";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useTranslation } from "../../../locale/useTranslation";
import * as API from "../../../framework/API/api";
import * as API_DIGITAL_SIGN from "../../../framework/API/api_digitalSign";
import { toast } from "react-toastify";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../../framework/utility/utilityProvider";
import { getter } from "@progress/kendo-data-query";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import { Loader } from "../../../framework/forms/helpercomponents/Loader";
import LiveEventCard from "../../LiveEvents/components/LiveEventCard";
import { ContextMenu } from "primereact/contextmenu";
import { MenuCommandCell } from "../../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../../ConfirmAlert";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import IconSquareButton from "../../../framework/forms/helpercomponents/buttons/IconSquareButton";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { ProgressBar } from "@progress/kendo-react-progressbars";
import DigitalSignCollectionSelection from "../DigitalSignCollectionSelection";
import { useLocation } from "react-router-dom";
import ContentPreviewPopup from "../component/ContentPreviewPopup";
import BossDialog from "../../../components/BossDialog";
import MyCompanyCell from "../component/MyCompanyCell";
import { Input } from "@progress/kendo-react-inputs";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);
const CLOUD_NAME = "dlpms1zot";
const UPLOAD_PRESET = "my_preset_3";

export const contentMenuModel = (props) => {

    const menu = [
        {
            label: 'Options',
            items: [
                { label: 'Preview', icon: 'pi pi-fw pi-eye', command: () => props.onPreview({ ...props.dataItem }) },
                { label: 'Publish', icon: 'pi pi-fw pi-calendar', command: () => props.onPublish({ ...props.dataItem }) },
                { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
                {
                    label: `Set as ${props.dataItem?.orientation == ORIENTATION.Portrait ? utility.getKeyByValue(ORIENTATION, ORIENTATION.Landscape) : utility.getKeyByValue(ORIENTATION, ORIENTATION.Portrait)}`, icon: `pi pi-fw pi-mobile ${props.dataItem?.orientation === ORIENTATION.Landscape ? "" : "rotate-icon"}`, command: () => props.onOrientation({ ...props.dataItem })
                },
            ]
        }
    ]

    return menu;
}

export default function DigiSignContentCollection(props) {

    const lang = useTranslation();
    const location = useLocation();

    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    const [selectedCompany, setSelectedCompany] = useState({});

    const contextMenuRef = useRef();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [selectedState, setSelectedState] = useState({});
    const [tileView, setTileView] = useState(true);
    const selectedFileRef = useRef([]);
    const [files, setFiles] = useState([]);
    const [displayFiles, setDisplayFiles] = useState([]);

    const [showFileProgressPopup, setShowFileProgressPopup] = useState(false);
    const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const [onEditPopup, setOnEditPopup] = useState(false);
    const [showYoutubeContentPopup, setShowYoutubeContentPopup] = useState(false);

    const selectedItemRef = useRef([]);
    const [selectedItem, _setSelectedItem] = useState({});
    const setSelectedItem = (data) => {
        selectedItemRef.current = data;
        _setSelectedItem(data);
    };

    const [showAddStreamPopup, setShowAddStreamPopup] = useState(false);
    const [addStreamDetail, setAddStreamDetail] = useState({
        name: "",
        url: ""
    });

    const urlParams = new URLSearchParams(location?.search ?? "");
    const isOpenNewForm = urlParams?.get('openNewEditForm');

    // open by defualt create form
    useEffect(() => {
        if (location.state?.openNewEditForm || isOpenNewForm) {
            setOnEditPopup(true);
        }
    }, [location.state?.openNewEditForm || isOpenNewForm]);

    const [filterDataItem, setFilterDataItem] = useState({
        FileType: { _id: 0, value: "All" },
        Orientation: { _id: 0, value: "All" },
        Company: { _id: 0, Name: "All" },
    })

    const [dataItem, setDataItem] = useState({});

    useEffect(() => {
        loadData({ isSilent: false });
    }, []);

    const loadData = async ({ contentType = 0, orientation = 0, isSilent = false, Company = 0 }) => {

        !isSilent && setIsLoading(true);

        setSelectedCompany(companies?.length > 0 ? companies[0] : {});

        let query = [];
        if (contentType && contentType != 0) {
            query = [...query, ["content_type", "=", contentType]];
        }

        if (orientation && orientation != 0) {
            query = [...query, ["orientation", "=", orientation]];
        }

        if (Company && Company != 0) {
            query = [...query, ["Company_id", "=", Company]];
        }

        let res = await API_DIGITAL_SIGN.getData(ENTITYNAME.DigitalSignContent, { query: query });
        if (res.success) {
            setIsLoading(false);
            console.log(res.data);
            let gridData = res.data.map((x) => {
                return {
                    ...x,
                    PosterUrl: x.thumbnailUrl ?? x.url,
                    bytes: bytesToSize(x.bytes),
                    format: x.format ?? " ",
                }
            });
            setFilterData(gridData);
            setData(gridData);
        } else {
            toast.error(res.message);
        }
    }

    const onDeleteContent = async (item) => {

        let payload = {
            _id: item._id,
            publicId: item.public_id,
            resourceType: item.resource_type,
            content_type: item.content_type
        }

        const res = await API_DIGITAL_SIGN.deleteContent(payload);

        if (res.success) {
            toast.success(res.message, { position: toast.POSITION.TOP_RIGHT });
            loadData({ contentType: filterDataItem.FileType._id });
        } else {
            toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const handleFiltersChange = async (e) => {
        const value = e.target.value;
        if (e.target.name == "FileType") {
            setFilterDataItem({ ...filterDataItem, [e.target.name]: value });
            await loadData({ contentType: value._id, orientation: filterDataItem.Orientation._id, Company: filterDataItem.Company._id });
        } else if (e.target.name == "Orientation") {
            setFilterDataItem({ ...filterDataItem, [e.target.name]: value });
            await loadData({ contentType: filterDataItem.FileType._id, orientation: value._id, Company: filterDataItem.Company._id });
        } else if (e.target.name == "Company") {
            setFilterDataItem({ ...filterDataItem, Company: value });
            await loadData({ contentType: filterDataItem.FileType._id, orientation: filterDataItem.Orientation._id, Company: value._id });
        }
    };

    const onRefresh = async ({ isSilent = false }) => {
        setFilterDataItem({ FileType: { _id: 0, value: "All" }, Orientation: { _id: 0, value: "All" }, Company: { _id: 0, Name: "All" } });
        setSelectedCompany({});
        await loadData({ isSilent: isSilent });
    };


    const contenMenu = [
        { label: "Publish", icon: "fa-solid fa-calendar-days fa-lg", command: () => { setShowPlaylistPopup(true) } },
        { label: "Delete", icon: "fa-solid fa-trash fa-lg", command: () => ConfirmAlert(() => onDeleteContent(selectedItemRef.current), () => { }, lang.confirm_delete_label, lang.do_you_really_want_to_delete_question) },
        { label: "Preview", icon: "fa-solid fa-eye fa-lg", command: () => { setShowPreview(true) } },
        {
            label: `Set as ${selectedItemRef.current?.orientation == ORIENTATION.Portrait ? utility.getKeyByValue(ORIENTATION, ORIENTATION.Landscape) : utility.getKeyByValue(ORIENTATION, ORIENTATION.Portrait)}`, icon: `fa-solid fa-${selectedItemRef.current?.orientation == ORIENTATION.Landscape ? "mobile-screen-button" : "mobile-screen-button fa-rotate-90"
                } fa-lg`, command: () => { onOrientation(selectedItemRef.current) }
        },
    ];

    const onContextMenu = (e, item) => {
        contextMenuRef.current.show(e);
        setSelectedItem(item);
    };

    //CONVERT BYTES TO MB
    const bytesToSize = (bytes) => {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes == 0) return "0 KB";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i == 0) return bytes + " " + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
    };

    const generateUniqueUploadId = () => {
        return `uqid-${Date.now()}`;
    };

    const onFileChange = async (event) => {
        console.log(event)
        const files = Array.from(event.target.files);
        let totalSizeInBytes = files.reduce((acc, x) => acc + x.size, 0);
        const finalCompanyId = isEnterpriseLogin ? selectedCompany._id : company?._id;
        let canUploadContentRes = await API_DIGITAL_SIGN.canUploadContent(totalSizeInBytes, finalCompanyId);
        if (!canUploadContentRes.success) {
            toast.error(canUploadContentRes.message, { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        setFiles(files);
        let displayFilesLocal = files.map((x) => { return { name: x.name, percentage: 'Uploading..', progress: 0 } })
        setDisplayFiles(displayFilesLocal);
        selectedFileRef.current = files
        setShowFileProgressPopup(true);
        uploadFiles(files, displayFilesLocal);
    }

    const uploadFiles = async (uploadFiles, displayFilesLocal) => {
        if (uploadFiles.length == 0) {
            console.error('Please select a files.');
            toast.error('Please select a files.', {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setOnEditPopup(false);

        for (let i = 0; i < uploadFiles.length; i++) {

            const file = uploadFiles[i];
            const uniqueUploadId = generateUniqueUploadId();
            const chunkSize = 5 * 1024 * 1024; // Allowed upto 5MB.
            const totalChunks = Math.ceil(file.size / chunkSize);
            let currentChunk = 0;


            const uploadChunk = async (start, end) => {
                const formData = new FormData();
                formData.append('file', file.slice(start, end));
                formData.append('cloud_name', CLOUD_NAME);
                formData.append('upload_preset', UPLOAD_PRESET);
                formData.append('tags', company?._id.toString());

                const contentRange = `bytes ${start}-${end - 1}/${file.size}`;

                console.log(`Uploading chunk for uniqueUploadId: ${uniqueUploadId}; start: ${start}, end: ${end - 1}`);

                try {
                    const response = await fetch(
                        `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/auto/upload`,
                        {
                            method: 'POST',
                            body: formData,
                            headers: {
                                'X-Unique-Upload-Id': uniqueUploadId,
                                'Content-Range': contentRange,

                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error('Chunk upload failed.');
                    }

                    currentChunk++;

                    let percentage = Math.round((currentChunk / totalChunks) * 100);
                    var localFiles = displayFilesLocal;
                    console.log(localFiles[i]);
                    if (currentChunk < totalChunks) {
                        const nextStart = currentChunk * chunkSize;
                        const nextEnd = Math.min(nextStart + chunkSize, file.size);

                        //CALCULATE PERCENTAGE
                        console.log(percentage);


                        setDisplayFiles(prevDisplayFiles => {
                            const updatedFiles = [...prevDisplayFiles];
                            updatedFiles[i] = { ...updatedFiles[i], percentage: `${percentage}%`, progress: percentage };
                            return updatedFiles;
                        });


                        await uploadChunk(nextStart, nextEnd);

                    } else {

                        const fetchResponse = await response.json();
                        console.log(fetchResponse)

                        setDisplayFiles(prevDisplayFiles => {
                            const updatedFiles = [...prevDisplayFiles];
                            updatedFiles[i] = { ...updatedFiles[i], percentage: `100%`, progress: 100 };
                            return updatedFiles;
                        });


                        //SAVE DATA IN DB
                        await handleSubmit({ fileName: file.name, ...fetchResponse })
                        console.info('File upload complete.');
                        onRefresh({ isSilent: true });
                    }
                } catch (error) {
                    console.error('Error uploading chunk:', error);
                }
            };

            const start = 0;
            const end = Math.min(chunkSize, file.size);
            await uploadChunk(start, end);

        }

        onCloseFileUploadPopup();

    };

    // TODO: Fine Tune this function
    // const uploadFiles = async (uploadFiles, displayFilesLocal) => {
    //     if (uploadFiles.length == 0) {
    //         console.error('Please select a files.');
    //         toast.error('Please select a files.', {
    //             position: toast.POSITION.TOP_RIGHT
    //         });
    //         return;
    //     }
    //     setOnEditPopup(false);

    //     for (let i = 0; i < uploadFiles.length; i++) {
    //         await uploadFileInChunks(uploadFiles[i], async (data) => {
    //             if (data.success) {
    //                 setDisplayFiles(prevDisplayFiles => {
    //                     const updatedFiles = [...prevDisplayFiles];
    //                     updatedFiles[i] = { ...updatedFiles[i], percentage: `${data.progress}%`, progress: data.progress };
    //                     return updatedFiles;
    //                 });
    //                 if (data.progress == 100) {
    //                     await handleSubmit({ fileName: uploadFiles[i].name, ...data.result })
    //                     onRefresh({ isSilent: true });
    //                 }
    //             } else {
    //                 toast.error(data.message, { position: toast.POSITION.TOP_RIGHT });
    //             }
    //         });
    //     }

    //     onCloseFileUploadPopup();

    // };

    const handleYoutubeContentSubmit = async () => {
        if (!dataItem.FileName || dataItem.FileName == '') {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (!dataItem.Link || dataItem.Link == '') {
            toast.error(lang.please_enter_youtube_link_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let saveItem = {
            fileName: dataItem.FileName,
            playback_url: dataItem.Link,
            secure_url: dataItem.Link,
            content_type: CONTENT_TYPE.YouTube,
            resource_type: 'video',
            bytes: 0,
            url: dataItem.Link,
        }
        await handleSubmit(saveItem);
        setShowYoutubeContentPopup(false);
        onRefresh({ isSilent: false });
        setDataItem({});
    }

    const handleSubmit = async (dataItem) => {

        const item = {
            fileName: dataItem?.fileName,
            asset_id: dataItem?.asset_id,
            public_id: dataItem?.public_id,
            format: dataItem?.format,
            resource_type: dataItem?.resource_type,
            content_type: dataItem.content_type ?? CONTENT_TYPE[utility.convertFirstLetterToUpperCase(dataItem.resource_type)],
            bytes: dataItem?.bytes,
            url: dataItem?.url,
            secure_url: dataItem?.secure_url,
            playback_url: dataItem?.playback_url ? dataItem.playback_url : '',
            duration: CONTENT_TYPE[utility.convertFirstLetterToUpperCase(dataItem.resource_type)] == CONTENT_TYPE.Image ? 10 * 1000 : dataItem.duration ? dataItem.duration * 1000 : 10 * 1000,//10 seconds hardcoded for image
            orientation: dataItem?.orientation ?? utility.calculateAspectRatio(dataItem?.width, dataItem?.height),
            thumbnailUrl: dataItem?.thumbnailUrl ?? utility.getThumbnailUrl(dataItem?.url, dataItem?.content_type ?? CONTENT_TYPE[utility.convertFirstLetterToUpperCase(dataItem.resource_type)]),
            Company_id: isEnterpriseLogin ? selectedCompany._id : company?._id
        }
        const res = await API_DIGITAL_SIGN.saveData(ENTITYNAME.DigitalSignContent, item);
        if (res.success) {
            let logData = { event: LOGEVENT.CREATE_CONTENT, module: MODULE.DIGITAL_SIGN_CONTENT, data: res.data, message: res.message };
            API.SaveLogs(logData);
            onRefresh({ isSilent: false });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const onPublishContent = async (playlists) => {

        for (let i = 0; i < playlists.length; i++) {
            const playlist = playlists[i];
            let res = await API_DIGITAL_SIGN.getData(ENTITYNAME.DigitalSignPlaylistItem, { playlist_id: playlist._id });

            if (res.success) {

                //GET THE HIGHEST SEQUENCE
                let sequence = res.data.length > 0 ? res.data[res.data.length - 1].sequenceIndex + 1 : 0;
                let saveData = {
                    playlist_id: playlist._id,
                    content_id: selectedItem?._id?.toString(),
                    sequenceIndex: sequence,
                    overlay: [],
                }

                await API_DIGITAL_SIGN.saveData(ENTITYNAME.DigitalSignPlaylistItem, saveData);

            } else {
                console.log(res)
            }

        }

        toast.success("Content published in selected playlists successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });

        setShowPlaylistPopup(false);
    }

    const onCloseFileUploadPopup = () => {
        setShowFileProgressPopup(false);
        onRefresh({ isSilent: false });
    }

    const onOrientation = async (item) => {
        let orientationValue = item?.orientation == ORIENTATION.Portrait ? ORIENTATION.Landscape : ORIENTATION.Portrait;
        let res = await API_DIGITAL_SIGN.saveData(ENTITYNAME.DigitalSignContent, { _id : item?._id, Company_id: item?.Company_id, orientation: orientationValue });
        if (res.success) {
            loadData({ contentType: filterDataItem.FileType._id });
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const MyCommandCell = (props) => (
        <MenuCommandCell
            {...props}
            onPublish={() => {
                setShowPlaylistPopup(true);
                setSelectedItem(props.dataItem)
            }}
            onDelete={onDeleteContent}
            onPreview={() => {
                setShowPreview(true);
                setSelectedItem(props.dataItem);
            }}
            onOrientation={onOrientation}
            myMenuModel={contentMenuModel}
        />
    )

    //POPUP TABLE STYLE
    const tableDataStyle = {
        fontFamily: "Roboto",
        fontSize: "13px",
        fontWeight: "400",
        letterSpacing: "0.2px",
        lineHeight: "18px",
        color: "#3C4048",
        padding: "10px 10px",
        width: "360px"
    }

    const onSearch = (e) => {
        const value = e.target.value;
        if (value != "") {
            const filteredData = filterData?.length > 0 ? filterData.filter((item) => {
                return Object.keys(item).some((key) =>
                    item[key].toString().toLowerCase().includes(value.toLowerCase())
                );
            }) : [];
            setData(filteredData);
        } else {
            setData(filterData);
        }
    }

    const onShowAddStreamPopup = () => {
        setShowAddStreamPopup(!showAddStreamPopup);
        setAddStreamDetail({ name: "", url: "" });
    }

    const onAddStream = async () => {
        if (addStreamDetail.name.length == 0) {
            toast.error("Name is required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (addStreamDetail.url.length == 0) {
            toast.error("Url is required", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }


        let saveItem = {
            fileName: addStreamDetail.name,
            asset_id: "",
            public_id: addStreamDetail.name,
            format: "Stream",
            resource_type: "Stream",
            content_type: CONTENT_TYPE.Stream,
            bytes: 0,
            url: addStreamDetail.url,
            secure_url: addStreamDetail.url,
            playback_url: addStreamDetail.url,
            duration: 10,//10 seconds hardcoded for stream
            orientation: ORIENTATION.Landscape,
            thumbnailUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRAZ8fePOkUqIolT6yrGJiR4vn_izK3zeytxg&s",
            Company_id: isEnterpriseLogin ? selectedCompany._id : company?._id
        }
        await handleSubmit(saveItem);
        onShowAddStreamPopup();
        setOnEditPopup(false);
    }



    return (
        <div>
            <div className="row m-1">
                <div className="col-8">
                    <h1
                        className="page-title txt-color-bludeDark"
                        style={{ margin: "10px 0" }}
                    >
                        <i
                            className="fa fa-tv"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span
                            style={{
                                fontFamily: "sans-serif",
                                paddingLeft: "5px",
                                letterSpacing: "0.5px",
                            }}
                        >
                            Total Contents - {data?.length}
                        </span>
                    </h1>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <div className="pointer p-1" onClick={() => setTileView(!tileView)}
                        title={tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`}
                    >
                        <i className={`fas ${tileView ? "fa-table-cells" : "fa-table-list"} fa-xl`} />
                    </div>
                </div>
            </div>
            <div className="flex-container ml-2 mr-2">
                <div className="mt-3">
                    <AddRoundButton onClick={() => { setOnEditPopup(true) }} />
                    <RefreshButton onClick={() => onRefresh({ isSilent: false })} />
                </div>
                <div className="flex-container-reverse flex-item-auto mr-4">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </div>
                    <div className="mr-2" style={{ marginTop: '-10px', width: '150px' }}>
                        <label htmlFor="TabView">{lang.orientation_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={[
                                { _id: 0, value: "All" },
                                ...Object.keys(ORIENTATION).map((key) => {
                                    return { _id: ORIENTATION[key], value: key }
                                })
                            ]}
                            name="Orientation"
                            textField="value"
                            dataItemKey="_id"
                            value={filterDataItem.Orientation}
                            onChange={handleFiltersChange}
                        />
                    </div>
                    <div className="mr-2" style={{ marginTop: '-10px', width: '150px' }}>
                        <label htmlFor="TabView">{lang.content_type_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={[
                                { _id: 0, value: "All" },
                                ...Object.keys(CONTENT_TYPE).map((key) => {
                                    return { _id: CONTENT_TYPE[key], value: key }
                                })
                            ]}
                            name="FileType"
                            textField="value"
                            dataItemKey="_id"
                            value={filterDataItem.FileType}
                            onChange={handleFiltersChange}
                        />
                    </div>
                    {isEnterpriseLogin && <div className="mr-2" style={{ marginTop: '-10px', width: '150px' }}>
                        <label htmlFor="TabView">{lang.company_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={[
                                { _id: 0, Name: "All" },
                                ...companies
                            ]}
                            name="Company"
                            textField="Name"
                            dataItemKey="_id"
                            value={filterDataItem.Company}
                            onChange={handleFiltersChange}
                        />
                    </div>}
                </div>
            </div>
            <div className="row mr-1" style={{ height: "74vh" }}>
                {isLoading ? (
                    <Loader height={"65vh"} />
                ) : tileView ? (
                    data && data.length == 0 ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80%', width: '100%', fontWeight: "500", color: "grey" }}><AddRoundButton onClick={() => { setOnEditPopup(true) }} /> Upload New Content</div> :
                        <div style={{ height: "100%", width: "100%", overflow: "auto", display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}>{
                            data.map((content, index) => (
                                <LiveEventCard
                                    item={content}
                                    showLive={false}
                                    cardInline={2}
                                    tooltip={`${content.fileName +
                                        "\n" +
                                        content.bytes +
                                        "\n" +
                                        content.format +
                                        "\n"}`
                                    }
                                    title={content.fileName}
                                    description={content.bytes}
                                    subDescription={content.addOn ? `Added on : ${utility.convertMilisecondsToDateTimeString(content.addOn)}` : ''}
                                    subDescription2={content.format}
                                    index={index}
                                    onContextMenu={onContextMenu}
                                    showOrientationIcon={true}
                                    showIcon={true}
                                    showCompanyName={true}
                                />
                            ))
                        }</div>) : (
                    <Grid
                        style={{ margin: "10px", height: "100%", width: "100%" }}
                        data={data.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        resizable={true}
                        selectable={{
                            enabled: true,
                            cell: false,
                            mode: "multiple",
                        }}
                    >
                        <Column cell={MyCommandCell} width={"60px"} locked={true} />
                        <Column
                            field="PosterUrl"
                            title={"Thumbnail"}
                            editable={false}
                            cell={ImageCell}
                            width={"100px"}
                        />
                        <Column
                            field="fileName"
                            title={"File Name"}
                            editable={false}
                            width={200}
                        />
                        <Column
                            field="bytes"
                            title={"Size"}
                            editable={false}
                            width={200}
                        />

                        <Column
                            field="content_type"
                            title={"Type"}
                            editable={false}
                            cell={EnumCell}
                            width={200}
                        />

                        <Column
                            field="orientation"
                            title={"Orientation"}
                            editable={false}
                            cell={EnumCell}
                            width={200}
                        />

                        <Column
                            field="format"
                            title={"Format"}
                            editable={false}
                            width={200}
                        />

                        {isEnterpriseLogin && <Column
                            field="Company_id"
                            title={"Company"}
                            cell={MyCompanyCell}
                            width={200}
                        />}

                    </Grid>
                )}
            </div>

            {showFileProgressPopup && <BossDialog
                title={"File Progress"}
                onClose={onCloseFileUploadPopup}
                width={"750px"}
            >
                <div>

                    <table className="table mt-2">
                        <thead>
                            <tr>
                                <th scope="col">{lang.file_name_column}</th>
                                <th scope="col">{lang.progress_column}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayFiles?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={tableDataStyle}>
                                            {`${item?.name?.length > 50 ? item?.name?.substring(0, 50) + "..." : item?.name}`}
                                        </td>
                                        <td style={tableDataStyle}>
                                            <ProgressBar
                                                label={(props) => {
                                                    return <strong>{item?.percentage}</strong>;
                                                }}
                                                labelPlacement='center'
                                                progressStyle={{ backgroundColor: "green" }}
                                                value={item?.progress}
                                                style={{ width: "100%" }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </BossDialog>}

            {/* content type selector */}
            {onEditPopup && <BossDialog width={showAddStreamPopup && "400px"} title={showAddStreamPopup ? "Add Stream" : "Select Content Type"} onClose={() => showAddStreamPopup ? onShowAddStreamPopup() : setOnEditPopup(false)} >
                {isEnterpriseLogin && <div className="mb-2">
                    <label htmlFor="TabView">{lang.company_label} *</label>
                    <DropDownList
                        data={companies}
                        name="Company"
                        textField="Name"
                        dataItemKey="_id"
                        value={selectedCompany}
                        onChange={(e) => setSelectedCompany(e.target.value)}
                    />
                </div>}
                {showAddStreamPopup ?
                    <div>
                        <EditPageHeader showTitle={false} saveButtonTitle={'Add'} onCancel={onShowAddStreamPopup} onSubmit={onAddStream} />
                        {/* input to take name of stream */}
                        <div className="d-flex flex-column">
                            <Input
                                type="text"
                                name="Name"
                                label="Name"
                                value={addStreamDetail.name}
                                onChange={(e) => setAddStreamDetail({
                                    name: e.target.value,
                                    url: addStreamDetail.url
                                })}
                            />
                            <Input
                                type="text"
                                name="Url"
                                label="Url"
                                value={addStreamDetail.url}
                                onChange={(e) => setAddStreamDetail({
                                    name: addStreamDetail.name,
                                    url: e.target.value
                                })}
                            />
                        </div>
                    </div>
                    : <div className="d-flex">
                        <IconSquareButton name={'Image'} title={'Add Images'} width={'100px'} height={'100px'} borderRadius={'25px'} icon={'fa-image'} iconSize={'50px'} onFileChange={onFileChange} inputField={true} buttonType={'file'} accept={'image/*'} backgroundColor={'green'} />
                        <IconSquareButton name={'Video'} title={'Add Videos'} width={'100px'} height={'100px'} borderRadius={'25px'} icon={'fa-film'} iconSize={'50px'} onFileChange={onFileChange} inputField={true} buttonType={'file'} accept={'video/*'} backgroundColor={'darkblue'} />
                        <IconSquareButton name={'Youtube'} title={'Add Youtube Link'} width={'100px'} height={'100px'} borderRadius={'25px'} icon={'fa-brands fa-youtube'} iconSize={'50px'} onClick={() => { setShowYoutubeContentPopup(true); setOnEditPopup(false) }} backgroundColor={'red'} />
                        <IconSquareButton name={'Stream'} title={'Add Stream'} width={'100px'} height={'100px'} borderRadius={'25px'} onClick={onShowAddStreamPopup} icon={'fa-stream'} iconSize={'50px'} backgroundColor={'purple'} />
                    </div>}
            </BossDialog>}

            <ContextMenu model={contenMenu} ref={contextMenuRef} />

            {showPlaylistPopup && <DigitalSignCollectionSelection entityname={ENTITYNAME.DigitalSignPlaylist} closeForm={() => { setShowPlaylistPopup(false) }} setDataList={onPublishContent} title={'Select Playlist'} width={"800px"} />}

            {/* YouTube Content Popup */}
            {showYoutubeContentPopup && <BossDialog
                title={"Add YouTube Content"}
                onClose={() => { setShowYoutubeContentPopup(false) }}
                width={"500px"}
            >
                <EditPageHeader onSubmit={handleYoutubeContentSubmit} saveButtonTitle={'Add'} onCancel={() => setShowYoutubeContentPopup(false)} showTitle={false} />

                <div className="form-group mt-2">
                    <label htmlFor="">{lang.name_label} *</label>
                    <input type="text" name="FileName" className="form-control" placeholder="Name" value={dataItem.FileName} onChange={onChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="">{lang.link_label} *</label>
                    <input type="text" name="Link" className="form-control" placeholder="Youtube Link" value={dataItem.Link} onChange={onChange} />
                </div>

            </BossDialog>}

            {showPreview && <ContentPreviewPopup selectedItem={selectedItem} onClose={() => setShowPreview(false)} />}

        </div>
    );
}
